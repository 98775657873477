import { BossNames, EnemyDeckNames } from 'types/enemies.types';

type BossStat = {
  level: number;
  health: string;
  move: number;
  attack: number | string;
  range: number;
  special1: string[];
  special2: string[];
  immunities: string[];
  notes: string;
};

type BossInfo = {
  deck: EnemyDeckNames.Boss;
  name?: BossNames;
  level: BossStat[];
};

export const BOSS_STATS: Record<BossNames, BossInfo> = {
  [BossNames.BanditCommander]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '8xC',
        move: 3,
        attack: 3,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
      {
        level: 1,
        health: '10xC',
        move: 3,
        attack: 3,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
      {
        level: 2,
        health: '12xC',
        move: 4,
        attack: 3,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
      {
        level: 3,
        health: '13xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
      {
        level: 4,
        health: '15xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
      {
        level: 5,
        health: '16xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
      {
        level: 6,
        health: '19xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
      {
        level: 7,
        health: '23xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: ['Move to next door and reveal room'],
        special2: ['Summon Living Bones'],
        immunities: ['%stun-icon%', '%immobilize-icon%', '%curse-icon%'],
        notes: '',
      },
    ],
  },
  [BossNames.TheBetrayer]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '10xC',
        move: 3,
        attack: 4,
        range: 3,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '12xC',
        move: 3,
        attack: 5,
        range: 3,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '14xC',
        move: 3,
        attack: 6,
        range: 4,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '16xC',
        move: 4,
        attack: 7,
        range: 4,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '18xC',
        move: 4,
        attack: 8,
        range: 4,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '20xC',
        move: 5,
        attack: 8,
        range: 5,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '23xC',
        move: 5,
        attack: 9,
        range: 5,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '27xC',
        move: 5,
        attack: 9,
        range: 5,
        special1: ['Summon Giant Viper and Fear'],
        special2: ['Mind Control'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.CaptainOfTheGuard]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '7xC',
        move: 2,
        attack: 3,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '9xC',
        move: 2,
        attack: 3,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '11xC',
        move: 2,
        attack: 4,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '14xC',
        move: 3,
        attack: 4,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '16xC',
        move: 3,
        attack: 5,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '20xC',
        move: 3,
        attack: 5,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '21xC',
        move: 4,
        attack: 6,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '25xC',
        move: 4,
        attack: 6,
        range: 0,
        special1: ['%heal% 2, Affect self and all allies'],
        special2: [
          'All allies add +1 Attack to all attacks this round',
          '%attack% +1',
        ],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.TheColorless]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '9xC',
        move: 3,
        attack: 2,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 4, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '10xC',
        move: 3,
        attack: 3,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 4, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '11xC',
        move: 4,
        attack: 3,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 5, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '12xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 5, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '14xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 6, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '15xC',
        move: 4,
        attack: 5,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 6, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '17xC',
        move: 4,
        attack: 6,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 7, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '19xC',
        move: 5,
        attack: 7,
        range: 0,
        special1: [
          '%dark%%use_element%: Summon Night Demon',
          '%invisible%, Self',
        ],
        special2: [
          '%light%%use_element%: Summon Sun Demon',
          '%heal% 7, Self',
          '%shield% 1',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.DarkRider]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '9xC',
        move: 2,
        attack: '3+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
      {
        level: 1,
        health: '10xC',
        move: 3,
        attack: '3+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
      {
        level: 2,
        health: '12xC',
        move: 3,
        attack: '3+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
      {
        level: 3,
        health: '13xC',
        move: 3,
        attack: '4+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
      {
        level: 4,
        health: '15xC',
        move: 3,
        attack: '4+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
      {
        level: 5,
        health: '16xC',
        move: 3,
        attack: '5+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
      {
        level: 6,
        health: '16xC',
        move: 4,
        attack: '5+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
      {
        level: 7,
        health: '18xC',
        move: 4,
        attack: '6+X',
        range: 0,
        special1: ['%move% +2', '%attack% +0'],
        special2: ['%move% +0', 'Summon Imp', '%attack% -1'],
        immunities: [
          '%immobilize-icon%',
          '%poison-icon%',
          '%stun-icon%',
          '%disarm-icon%',
        ],
        notes: 'X = Hexes moved',
      },
    ],
  },
  [BossNames.ElderDrake]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '11xC',
        move: 0,
        attack: 3,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '12xC',
        move: 0,
        attack: 4,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '15xC',
        move: 0,
        attack: 4,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '16xC',
        move: 0,
        attack: 5,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '20xC',
        move: 0,
        attack: 5,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '22xC',
        move: 0,
        attack: 6,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '27xC',
        move: 0,
        attack: 6,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '29xC',
        move: 0,
        attack: 7,
        range: 0,
        special1: [
          '%attack% +0 <div class="collapse" style="position:absolute;right:0;top:-1em">%boss-aoe-elder-drake-sp1%</div>',
        ],
        special2: ['%move% and summon 2 Zephyrs'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.TheGloom]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '20xC',
        move: 2,
        attack: 5,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '%range% 5',
          '%poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '25xC',
        move: 2,
        attack: 5,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '** %range% 5',
          '** %poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '29xC',
        move: 2,
        attack: 6,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '** %range% 5',
          '** %poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '35xC',
        move: 2,
        attack: 6,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '** %range% 5',
          '** %poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '39xC',
        move: 3,
        attack: 7,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '** %range% 5',
          '** %poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '46xC',
        move: 3,
        attack: 7,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '** %range% 5',
          '** %poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '50xC',
        move: 3,
        attack: 8,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '** %range% 5',
          '** %poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '56xC',
        move: 3,
        attack: 9,
        range: 0,
        special1: ['%move% +9', '%attack% +9'],
        special2: [
          'Teleport',
          '%attack% +1',
          '** %range% 5',
          '** %poison%, %wound%, %stun%',
        ],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.InoxBodyguard]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '6xC',
        move: 2,
        attack: 'C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 3'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 1,
        health: '7xC',
        move: 2,
        attack: '1+C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 3'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 2,
        health: '9xC',
        move: 2,
        attack: '1+C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 3'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 3,
        health: '10xC',
        move: 3,
        attack: '2+C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 4'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 4,
        health: '11xC',
        move: 3,
        attack: '2+C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 4'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 5,
        health: '13xC',
        move: 3,
        attack: '3+C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 5'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 6,
        health: '15xC',
        move: 4,
        attack: '3+C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 5'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 7,
        health: '17xC',
        move: 4,
        attack: '4+C',
        range: 0,
        special1: [
          '%move% -1',
          '%attack% -1 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-inox-bodyguard-sp1%</div>',
        ],
        special2: ['%move% +0', '%attack% +0', '%retaliate% 5'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: 'C = Number of Characters',
      },
    ],
  },
  [BossNames.Jekserah]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '6xC',
        move: 2,
        attack: 2,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '7xC',
        move: 2,
        attack: 3,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '9xC',
        move: 3,
        attack: 3,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '12xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '13xC',
        move: 4,
        attack: 5,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '15xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '18xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '22xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: [
          'Summon Living Bones',
          '%attack% -1, Target all adjacent enemies',
        ],
        special2: ['Summon Living Corpse', '%move% -1', '%attack% +2'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.MercilessOverseer]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '6xC',
        move: 2,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
      {
        level: 1,
        health: '8xC',
        move: 2,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
      {
        level: 2,
        health: '9xC',
        move: 3,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
      {
        level: 3,
        health: '11xC',
        move: 3,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
      {
        level: 4,
        health: '12xC',
        move: 4,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
      {
        level: 5,
        health: '14xC',
        move: 4,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
      {
        level: 6,
        health: '16xC',
        move: 4,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
      {
        level: 7,
        health: '19xC',
        move: 4,
        attack: 'V',
        range: 0,
        special1: ['All Scouts act again'],
        special2: ['Summon Vermling Scout'],
        immunities: [
          '%disarm-icon%',
          '%wound-icon%',
          '%curse-icon%',
          '%stun-icon%',
        ],
        notes: 'V = Number of Scouts present',
      },
    ],
  },
  [BossNames.PrimeDemonScenario36]: {
    deck: EnemyDeckNames.Boss,
    name: BossNames.PrimeDemon,
    level: [
      {
        level: 0,
        health: '16xC',
        move: 3,
        attack: 4,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '18xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '20xC',
        move: 4,
        attack: 5,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '24xC',
        move: 4,
        attack: 6,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '28xC',
        move: 5,
        attack: 6,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '32xC',
        move: 5,
        attack: 7,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '40xC',
        move: 5,
        attack: 7,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '44xC',
        move: 5,
        attack: 8,
        range: 0,
        special1: ['%move% +0', '%attack% +0'],
        special2: ['%move% +0', '%attack% +0'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.PrimeDemon]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '8xC',
        move: 3,
        attack: 4,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '9xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '10xC',
        move: 4,
        attack: 5,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '12xC',
        move: 4,
        attack: 6,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '14xC',
        move: 5,
        attack: 6,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '16xC',
        move: 5,
        attack: 7,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '20xC',
        move: 5,
        attack: 7,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '22xC',
        move: 5,
        attack: 8,
        range: 0,
        special1: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        special2: ['Throne moves', 'Summon Demon', '%move% +2', '%attack% -1'],
        immunities: [
          '%wound-icon%',
          '%poison-icon%',
          '%disarm-icon%',
          '%immobilize-icon%',
          '%muddle-icon%',
          '%stun-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.TheSightlessEye]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '7xC',
        move: 0,
        attack: 5,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
          '** %range% +0',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:1em;top:0">%boss-aoe-sightless-eye-sp2%</div>',
          '** %range% +0',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
      {
        level: 1,
        health: '8xC',
        move: 0,
        attack: 6,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
          '** %range% +0',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:1em;top:0">%boss-aoe-sightless-eye-sp2%</div>',
          '** %range% +0',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '10xC',
        move: 0,
        attack: 6,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:2em;top:-1.5em">%boss-aoe-sightless-eye-sp2%</div>',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '11xC',
        move: 0,
        attack: 7,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
          '** %range% +0',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:1em;top:0">%boss-aoe-sightless-eye-sp2%</div>',
          '** %range% +0',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '14xC',
        move: 0,
        attack: 7,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
          '** %range% +0',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:1em;top:0">%boss-aoe-sightless-eye-sp2%</div>',
          '** %range% +0',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '15xC',
        move: 0,
        attack: 8,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
          '** %range% +0',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:1em;top:0">%boss-aoe-sightless-eye-sp2%</div>',
          '** %range% +0',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '18xC',
        move: 0,
        attack: 8,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
          '** %range% +0',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:1em;top:0">%boss-aoe-sightless-eye-sp2%</div>',
          '** %range% +0',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '20xC',
        move: 0,
        attack: 9,
        range: 3,
        special1: [
          'Summon Deep Terror',
          '%attack% -3 <div class="collapse" style="position:absolute;right:1.5em;top:-1em">%boss-aoe-sightless-eye-sp1%</div>',
        ],
        special2: [
          'Summon Deep Terror',
          '%attack% -2 <div class="collapse" style="position:absolute;right:2em;top:-1.5em">%boss-aoe-sightless-eye-sp2%</div>',
        ],
        immunities: [
          '%stun-icon%',
          '%disarm-icon%',
          '%curse-icon%',
          '%muddle-icon%',
        ],
        notes: '',
      },
    ],
  },
  [BossNames.WingedHorror]: {
    deck: EnemyDeckNames.Boss,
    level: [
      {
        level: 0,
        health: '6xC',
        move: 3,
        attack: 3,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: 'C = Number of Characters',
      },
      {
        level: 1,
        health: '7xC',
        move: 4,
        attack: 3,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 2,
        health: '8xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 3,
        health: '10xC',
        move: 4,
        attack: 4,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 4,
        health: '12xC',
        move: 5,
        attack: 4,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 5,
        health: '14xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 6,
        health: '17xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
      {
        level: 7,
        health: '20xC',
        move: 5,
        attack: 5,
        range: 0,
        special1: [
          '%attack% -1, Target all adjacent enemies',
          '%attack% +0, %range% 3',
          'Hatch eggs',
        ],
        special2: ['Summon C eggs', '%move% -1', '%attack% +0'],
        immunities: [
          '%disarm-icon%',
          '%poison-icon%',
          '%muddle-icon%',
          '%stun-icon%',
          '%curse-icon%',
        ],
        notes: '',
      },
    ],
  },
};
